import './internals/polyfills';

import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import Immutable from 'immutable';
import moment from 'moment';

import App from './components/App';
import Stream from './libs/Stream';
import browserRouter from './streams/browserRouter';
import dataStream from './streams/dataStream';

if (process.env.NODE_ENV === 'development') {
	(global as any).$ = $;
	(global as any)._ = _;
	(global as any).Immutable = Immutable;
	(global as any).Stream = Stream;
	(global as any).moment = moment;
}

// grid toggle
if (process.env.NODE_ENV === 'development') {
	const G_KEY_CODE = 71;

	$((global as any).document).on('keydown', (event) => {
		let tagName = event.target.tagName.toLowerCase();

		if (event.keyCode === G_KEY_CODE && event.target && tagName !== 'textarea' && tagName !== 'input') {
			$('body').toggleClass('hasGrid');
		}
	});
}

// init router
browserRouter.start();

// render app
dataStream.on(() => {
	let rootNode = document.getElementById('app');

	if (rootNode) {
		ReactDOM.render(<App />, rootNode);
	}

	$('#app').addClass('isLoaded');
});
